import React, { useCallback, useState } from 'react'
import {
  Message,
  Button,
  Form as FormR,
  TableRow,
  TableHeaderCell,
  TableHeader,
  TableCell,
  TableBody,
  Icon,
  Table,
} from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Link } from 'react-router-dom'

import {
  writeComment,
  changeTicketStatus,
  // deleteTicketRequest,
  // deleteAllOpenTicketsRequest,
} from '~/store/modules/websocket/actions'
import { updateTicketRequestDashboard } from '~/store/modules/ticket/actions'
import { Display, FormContainer } from './styles'
import AccordionCloud from '../Accordion'
import Tabulacao from '../../../../components/Tabulacao'
import { useGlobalContext } from '../../../../contexts/GlobalContext'
import api from '../../../../services/api'
import { toast } from 'react-toastify'

const options = [
  {
    key: 1,
    value: 1,
    text: 'Aberto',
    label: { color: 'green', empty: true, circular: true },
  },
  {
    key: 2,
    value: 2,
    text: 'Fechado',
    label: { color: 'black', empty: true, circular: true },
  },
  {
    key: 3,
    value: 3,
    text: 'Pendente',
    label: { color: 'yellow', empty: true, circular: true },
  },
]

export default function Form({ chamado }) {
  const [uploadProgress, setUploadProgress] = useState(0)
  const [isUploading, setIsUploading] = useState(false)

  const { globalState, globalDispatch } = useGlobalContext()
  const { tabulacoes } = globalState

  const dispatch = useDispatch()
  const profile = useSelector((state) => state.user.profile)
  const [templateFields, setTemplateFields] = React.useState([])

  const getTemplateFields = useCallback(async () => {
    const { data } = await api.get(`templatefields/${profile.id_dominio}`)
    setTemplateFields(data)
  }, [profile.id_dominio])

  React.useEffect(() => {
    getTemplateFields()
  }, [getTemplateFields])

  async function handleSubmit() {
    const { id, comentario, aberto, fk_id_tabulacao } = chamado

    if (aberto === 1) {
      const MySwal = withReactContent(Swal)
      MySwal.fire({
        type: 'error',
        title: 'Erro no Status',
        text: 'Chamado não pode ser salvo com status em aberto, selecione outro status',
      })
      return false
    }

    if (
      tabulacoes.length > 0 &&
      (fk_id_tabulacao === null || !fk_id_tabulacao)
    ) {
      const MySwal = withReactContent(Swal)
      MySwal.fire({
        type: 'error',
        title: 'Erro na Tabulação',
        text: 'Chamado não pode ser salvo sem tabulação, selecione uma tabulação',
      })
      return false
    }

    const { data } = await api.put(`tickets/${id}`, {
      aberto,
      comentario,
      fk_id_tabulacao: fk_id_tabulacao,
      fk_fechado_por: profile.id,
    })

    globalDispatch({
      type: 'SET_DATA',
      payload: {
        tickets: [
          ...globalState.tickets.filter((ticket) => ticket.id !== chamado.id),
        ],
        ticketIndex: null,
      },
    })
    return true
  }

  return (
    <>
      <Display>
        <div className="display">
          {chamado.fromComment && (
            <>
              <Message>
                <Message.Header>Originador</Message.Header>
                <Link to={`/contato/${chamado.id_from}`}>
                  <p>{chamado.from}</p>
                </Link>
              </Message>
              <Message>
                <Message.Header>Nome</Message.Header>
                <p>{chamado.fromComment}</p>
              </Message>
            </>
          )}
          {!chamado.fromComment && (
            <>
              <Message>
                <Message.Header>Originador</Message.Header>
                <p>{chamado.from}</p>
              </Message>
              <Message className="btn_message" color="red">
                <p>Contato inexistente</p>
                <Link
                  to={`/contatos/add?did=${chamado.from}&ticket=${chamado.id}`}
                >
                  <Button size="mini" type="button" positive>
                    Adicionar
                  </Button>
                </Link>
              </Message>
            </>
          )}

          {chamado.toComment && (
            <>
              <Message>
                <Message.Header>Destino</Message.Header>
                <p>{chamado.to}</p>
              </Message>
              <Message>
                <Message.Header>BU</Message.Header>
                <p>{chamado.toComment}</p>
              </Message>
            </>
          )}
          {!chamado.toComment && (
            <>
              <Message>
                <Message.Header>Destino</Message.Header>
                <p>{chamado.to}</p>
              </Message>
              <Message className="btn_message" color="red">
                <p>Destino não cadastrado</p>
                <Link
                  to={`/contatos/add?did=${chamado.to}&ticket=${chamado.id}`}
                >
                  <Button size="mini" type="button" positive>
                    Adicionar
                  </Button>
                </Link>
              </Message>
            </>
          )}
        </div>

        {chamado.detalhes && chamado.detalhes.length > 0 && (
          <FormR>
            <Message>
              <FormR.Group className="fields_personalizados">
                {chamado.detalhes.map((field) => {
                  const templateField = templateFields.find(
                    (template) => template.nome_campo === field.nome_campo,
                  )

                  if (templateField && templateField.tipo === 2) {
                    const conteudo =
                      templateField.template_fields_values.find(
                        (item) =>
                          Number.parseInt(item.id, 10) ===
                          Number.parseInt(field.conteudo, 10),
                      )?.valor || ''

                    return (
                      <FormR.Input
                        key={field.id}
                        name={field.nome_campo}
                        label={field.nome_campo}
                        placeholder={field.nome_campo}
                        value={conteudo}
                        readOnly
                      />
                    )
                  }

                  return (
                    <FormR.Input
                      key={field.id}
                      name={field.nome_campo}
                      label={field.nome_campo}
                      placeholder={field.nome_campo}
                      value={field.conteudo}
                      readOnly
                    />
                  )
                })}
              </FormR.Group>
            </Message>
          </FormR>
        )}

        {profile.historico &&
          profile.historico === 1 &&
          chamado.historico &&
          chamado.historico.length > 0 && (
            <AccordionCloud historico={chamado.historico} />
          )}

        {chamado.script && (
          <FormR>
            <FormR.TextArea
              label="Script de atendimento"
              placeholder="Script de atendimento não cadastrado"
              value={chamado.script}
              rows="8"
              readOnly
              className="script_atendimento"
            />
          </FormR>
        )}

        <FormContainer>
          <FormR className="form_chamado" onSubmit={handleSubmit}>
            <FormR.Select
              name="status"
              label="Status:"
              options={options}
              placeholder="Selecione uma opção"
              value={chamado.aberto}
              onChange={(e, v) =>
                // dispatch(
                //   changeTicketStatus({ id: chamado.id, aberto: v.value }),
                // )
                globalDispatch({
                  type: 'SET_DATA',
                  payload: {
                    tickets: [
                      ...globalState.tickets.filter(
                        (ticket) => ticket.id !== chamado.id,
                      ),
                      { ...chamado, aberto: v.value },
                    ],
                  },
                })
              }
            />

            {tabulacoes && tabulacoes.length > 0 && (
              <div>
                {/* biome-ignore lint/a11y/noLabelWithoutControl: <explanation> */}
                <label style={{ fontWeight: 'bold' }}>Tabulação:</label>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 10,
                    alignItems: 'center',
                    justifyContent: 'start',
                  }}
                >
                  <Tabulacao
                    id_ticket={chamado.id}
                    id_tabulacao={chamado.fk_id_tabulacao}
                  />

                  {/* adicione um input file multiplo hidden que faça upload por um botão estilo semantic ui */}
                  <div>
                    <input type="file" multiple hidden />
                    <Button
                      type="button"
                      color="blue"
                      onClick={() => {
                        const input =
                          document.querySelector('input[type="file"]')
                        input.click()
                        input.onchange = async (e) => {
                          const files = e.target.files
                          const formData = new FormData()

                          let total = 0
                          for (let i = 0; i < files.length; i++) {
                            const findFile = chamado?.files?.find(
                              (file) =>
                                file.file_name === files[i].name &&
                                file.size === files[i].size,
                            )
                            if (findFile) {
                              toast.error(
                                `Arquivo ${files[i].name} já existe`,
                                { autoClose: false },
                              )
                              continue
                            }

                            total++
                            formData.append('files', files[i])
                          }

                          if (total > 0) {
                            setIsUploading(true)
                            setUploadProgress(0)

                            try {
                              await api.post(
                                `tickets/${chamado.id}/upload`,
                                formData,
                                {
                                  headers: {
                                    'Content-Type': 'multipart/form-data',
                                  },
                                  onUploadProgress: (progressEvent) => {
                                    const percentCompleted = Math.round(
                                      (progressEvent.loaded * 100) /
                                        progressEvent.total,
                                    )
                                    setUploadProgress(percentCompleted)
                                  },
                                },
                              )

                              const response = await api.get('/tickets/open')
                              globalDispatch({
                                type: 'SET_DATA',
                                payload: {
                                  tickets: response.data,
                                },
                              })
                            } catch (error) {
                              toast.error(
                                'Erro ao fazer upload do arquivo. O limit para arquivo 50MB.',
                                { autoClose: false },
                              )
                              console.error('Upload failed:', error)
                            } finally {
                              setIsUploading(false)
                              input.value = ''
                              setUploadProgress(0)
                            }
                          }
                          input.value = ''
                        }
                      }}
                      disabled={isUploading}
                    >
                      {isUploading ? 'Enviando...' : 'Anexar arquivos'}
                    </Button>
                  </div>
                  <div
                    style={{
                      width: '150px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {isUploading && (
                      <div
                        style={{
                          marginTop: '10px',
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          flexDirection: 'column',
                        }}
                      >
                        <div
                          style={{
                            width: '100%',
                            backgroundColor: '#e0e0e0',
                            borderRadius: '4px',
                            marginBottom: '5px',
                          }}
                        >
                          <div
                            style={{
                              width: `${uploadProgress}%`,
                              height: '10px',
                              backgroundColor: '#3b82f6',
                              borderRadius: '4px',
                              transition: 'width 0.3s ease',
                            }}
                          />
                        </div>
                        <span>{uploadProgress}%</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

            <div style={{ marginTop: '10px', marginBottom: '10px' }}>
              {/* Adicioneu uma lista, onde serão exibidos nomes de arquivos, tamanho e dois botões 1 para download e outro para remover */}
              {chamado.files && chamado.files.length > 0 && (
                <Table celled striped>
                  <TableHeader>
                    <TableRow>
                      <TableHeaderCell colSpan="3">
                        Arquivos anexados
                      </TableHeaderCell>
                    </TableRow>
                  </TableHeader>

                  <TableBody>
                    {chamado.files.map((file) => {
                      return (
                        <TableRow key={file.id}>
                          <TableCell collapsing>
                            <Icon name="file outline" /> {file.file_name}
                          </TableCell>
                          <TableCell>
                            {(file.size / 1024).toFixed(2)} Kb
                          </TableCell>
                          <TableCell collapsing textAlign="right">
                            <Button
                              type="button"
                              color="blue"
                              onClick={async () => {
                                const { data } = await api.get(
                                  `tickets/${chamado.id}/download/${file.id}`,
                                )

                                // O codigo abaixo esta com erro de cors, como contornar ?
                                window.open(data.url, '_blank')
                              }}
                            >
                              Download
                            </Button>
                            <Button
                              type="button"
                              color="red"
                              onClick={async () => {
                                await api.delete(
                                  `tickets/${chamado.id}/delete/${file.id}`,
                                )

                                const response = await api.get('/tickets/open')
                                globalDispatch({
                                  type: 'SET_DATA',
                                  payload: {
                                    tickets: response.data,
                                  },
                                })
                              }}
                            >
                              Remover
                            </Button>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              )}
            </div>

            {/* {chamado.files &&
              chamado.files.length > 0 &&
              chamado.files.map((file) => {
                return (
                  <div
                    key={file.id}
                    className="file"
                    style={{
                      display: 'flex',
                      gap: '10px',
                      margin: '10px 0',
                      justifyContent: 'start',
                      alignItems: 'center',
                      // border: '1px solid #ccc',
                      padding: '10px',
                    }}
                  >
                    <h4 style={{ margin: '0px' }}>Arquivo: </h4>
                    <p style={{ margin: '0px' }}>{file.file_name}</p>
                    <p style={{ margin: '0px' }}>{file.size}</p>
                    <Button
                      type="button"
                      color="blue"
                      onClick={async () => {
                        const { data } = await api.get(
                          `tickets/${chamado.id}/download/${file.id}`,
                        )

                        // O codigo abaixo esta com erro de cors, como contornar ?
                        window.open(data.url, '_blank')
                      }}
                    >
                      Download
                    </Button>
                    <Button
                      type="button"
                      color="red"
                      onClick={async () => {
                        await api.delete(
                          `tickets/${chamado.id}/delete/${file.id}`,
                        )

                        const response = await api.get('/tickets/open')
                        globalDispatch({
                          type: 'SET_DATA',
                          payload: {
                            tickets: response.data,
                          },
                        })
                      }}
                    >
                      Remover
                    </Button>
                  </div>
                )
              })} */}

            <FormR.TextArea
              label="Detalhes do atendimento"
              placeholder="Dados da chamada da consultas futuras"
              rows="8"
              value={chamado.comentario || ''}
              onChange={(e) =>
                // dispatch(
                //   writeComment({ id: chamado.id, comment: e.target.value }),
                // )
                globalDispatch({
                  type: 'SET_DATA',
                  payload: {
                    tickets: [
                      ...globalState.tickets.filter(
                        (ticket) => ticket.id !== chamado.id,
                      ),
                      { ...chamado, comentario: e.target.value },
                    ],
                  },
                })
              }
            />

            <div className="btn_area">
              <div>
                <Button type="submit" color="blue">
                  Salvar
                </Button>
                {/* <Button
                  type="button"
                  color="red"
                  onClick={() => {
                    dispatch(deleteTicketRequest(chamado.id))
                  }}
                >
                  Descartar
                </Button> */}
              </div>
              {/* <div>
                <Button
                  type="button"
                  color="red"
                  onClick={() => dispatch(deleteAllOpenTicketsRequest())}
                >
                  Descartar Todos
                </Button>
              </div> */}
            </div>
          </FormR>
        </FormContainer>
      </Display>
    </>
  )
}

Form.propTypes = {
  chamado: PropTypes.shape({
    id: PropTypes.number,
    comentario: PropTypes.string,
    aberto: PropTypes.number,
    from: PropTypes.string,
    fromComment: PropTypes.string,
    to: PropTypes.string,
    toComment: PropTypes.string,
    script: PropTypes.string,
    historico: PropTypes.arrayOf(PropTypes.shape()),
    id_from: PropTypes.number,
    detalhes: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
}
