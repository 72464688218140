import React, { useEffect, useCallback } from 'react'
import { Switch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Route from './Route'
import { parse } from 'date-fns'

import SignIn from '../pages/SignIn'
// import Dashboard from '../pages/Dashboard'
// import Dashboard_Alliar from '../pages/Dashboard_Alliar'
import Dashboard_Rodobank from '../pages/Dashboard_Rodobank'
import Profile from '../pages/Profile'
import Usuarios from '../pages/Usuarios'
import UsuarioAdd from '../pages/Usuarios_Add'
import UsuarioEdt from '../pages/Usuarios_Edt'
import Chamados from '../pages/Tickets'
import Chamado from '../pages/Ticket'
import Contatos from '../pages/Contatos'
import Contato from '../pages/Contato'
import ContatoAdd from '../pages/Contato_Add'
import TemplateFields from '../pages/TemplateFields'
import Dominios from '../pages/Dominios'
// import Mailings from '../pages/Mailings';
import Mailings_V2 from '../pages/Mailings_V2'
import Ativo from '../pages/Ativo'
import Tabulacoes from '../pages/Tabulacoes'
import { useGlobalContext } from '../contexts/GlobalContext'
import api from '../services/api'
import apiV3 from '~/services/api_v3'
import { toast } from 'react-toastify'

export default function Routes() {
  const profile = useSelector((state) => state.user.profile)
  const { globalDispatch } = useGlobalContext()

  const fetchCallCenterBreakConfig = useCallback(async () => {
    if (!profile) return

    try {
      const { data } = await apiV3.get(
        `/contact/domain/${profile.dominio}/break_times`,
      )

      if (data) {
        globalDispatch({
          type: 'SET_DATA',
          payload: {
            callCenterBreakConfig: data,
          },
        })
      }
    } catch (error) {
      toast.error('Erro ao buscar configuração de pausas')
      console.error('Erro ao buscar configuração de pausas:', error)
    }
  }, [profile, globalDispatch])

  const fetchIsOnPause = useCallback(async () => {
    if (!profile) return

    try {
      const { data } = await apiV3.get(
        `/contact/domain/${profile.dominio}/user/${profile.user_basix}/isonpause`,
      )

      globalDispatch({
        type: 'SET_DATA',
        payload: {
          inCallCenterBreak: true,
          callCenterBreakCod: data.INT_PAUSE_CODE,
          callCenterBreakStart: parse(
            data.LAST_PAUSE,
            'dd-MM-yyyy HH:mm:ss',
            new Date(),
          ),
        },
      })
    } catch (error) {
      if (error.response.status === 404) {
        globalDispatch({
          type: 'SET_DATA',
          payload: {
            inCallCenterBreak: false,
            callCenterBreakCod: null,
            callCenterBreakStart: null,
          },
        })
        return
      }

      toast.error('Erro ao buscar status de pausa')
      console.error('Erro ao buscar status de pausa:', error)
    }
  }, [globalDispatch, profile])

  const fetchLoginStatus = useCallback(async () => {
    if (!profile) return

    try {
      const { data } = await apiV3.get(
        `/contact/domain/${profile.dominio}/user/${profile.user_basix}/islogged`,
      )

      if (data.DTM_LOGIN_TIME && data.DTM_LOGOUT_TIME) {
        globalDispatch({
          type: 'SET_DATA',
          payload: {
            callCenterLogin: false,
          },
        })
      }
      if (data.DTM_LOGIN_TIME && !data.DTM_LOGOUT_TIME) {
        globalDispatch({
          type: 'SET_DATA',
          payload: {
            callCenterLogin: true,
          },
        })
      }
    } catch (error) {
      toast.error('Erro ao buscar status de login')
      console.error('Erro ao buscar status de login:', error)
    }
  }, [globalDispatch, profile])

  const fetchCallCenterBreaks = useCallback(async () => {
    if (!profile) {
      return
    }

    if (!profile.callcenter_group) {
      return
    }

    try {
      const { data } = await api.get('/callcenter/pausas', {
        params: {
          domain: profile.dominio,
          group: profile.callcenter_group,
        },
      })

      if (data) {
        globalDispatch({
          type: 'SET_DATA',
          payload: {
            callcenterBreaks: data,
          },
        })
      }
    } catch (error) {
      toast.error('Erro ao buscar pausas')
      console.error('Erro ao buscar pausas:', error)
    }
  }, [globalDispatch, profile])

  useEffect(() => {
    if (profile) {
      fetchCallCenterBreaks()
      fetchLoginStatus()
      fetchIsOnPause()
      fetchCallCenterBreakConfig()
    }
  }, [
    fetchCallCenterBreaks,
    fetchLoginStatus,
    fetchIsOnPause,
    profile,
    fetchCallCenterBreakConfig,
  ])

  return (
    <Switch>
      <Route path="/" exact component={SignIn} />

      {/* <Route
        path="/dashboard"
        component={
          profile && profile.id_dominio === 17 ? Dashboard_Rodobank : Dashboard
        }
        isPrivate
      /> */}
      <Route path="/dashboard" component={Dashboard_Rodobank} isPrivate />
      <Route path="/profile" component={Profile} isPrivate />
      <Route path="/usuarios" exact component={Usuarios} isPrivate />
      <Route path="/usuarios/add" exact component={UsuarioAdd} isPrivate />
      <Route path="/usuarios/:id" component={UsuarioEdt} isPrivate />
      <Route path="/chamados" component={Chamados} isPrivate />
      <Route path="/chamado/:id" component={Chamado} isPrivate />
      <Route path="/contatos" exact component={Contatos} isPrivate />
      <Route path="/contatos/template" component={TemplateFields} isPrivate />
      <Route path="/contatos/add" component={ContatoAdd} isPrivate />
      <Route path="/contato/:id" component={Contato} isPrivate />
      <Route path="/dominios" component={Dominios} isPrivate />
      <Route path="/mailings" component={Mailings_V2} isPrivate />
      <Route path="/ativo" component={Ativo} isPrivate />
      <Route path="/tabulacoes" component={Tabulacoes} isPrivate />
    </Switch>
  )
}
