import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import history from '~/services/history'
import PropTypes from 'prop-types'
import {
  Button,
  Form,
  Message,
  Loader,
  Divider,
  Dropdown,
  DropdownMenu,
  Menu,
  TableRow,
  TableHeaderCell,
  TableHeader,
  TableCell,
  TableBody,
  Icon,
  Table,
} from 'semantic-ui-react'
import moment from 'moment'

import CustomDropdownItem from './dropdownItem'
import { FormContainer, ContainerTabulacao } from './styles'
import api from '~/services/api'
import { toast } from 'react-toastify'
import { useGlobalContext } from '../../contexts/GlobalContext'

function findItemNameById(id, estrutura) {
  for (let i = 0; i < estrutura.length; i++) {
    if (estrutura[i].id === id) {
      return estrutura[i].nome
    }

    if (estrutura[i].tabulations.length > 0) {
      const result = findItemNameById(id, estrutura[i].tabulations)
      if (result) return result
    }
  }
}

const options = [
  {
    key: 1,
    value: 1,
    text: 'Aberto',
    label: { color: 'green', empty: true, circular: true },
  },
  {
    key: 2,
    value: 2,
    text: 'Fechado',
    label: { color: 'black', empty: true, circular: true },
  },
  {
    key: 3,
    value: 3,
    text: 'Pendente',
    label: { color: 'yellow', empty: true, circular: true },
  },
]

export default function Ticket({ match }) {
  const [uploadProgress, setUploadProgress] = useState(0)
  const [isUploading, setIsUploading] = useState(false)

  const { globalDispatch } = useGlobalContext()

  const [ticket, setTicket] = useState({})
  const [loading, setLoading] = useState(false)
  const [tabulations, setTabulations] = useState([])

  const profile = useSelector((state) => state.user.profile)
  const { id } = match.params

  const [tabulacao, setTabulacaoLocal] = useState(null)
  const [status, setStatus] = useState()
  const [comentario, setComentario] = useState('')

  const [deDID, setDeDid] = useState('')
  const [deDescricao, setDeDescricao] = useState('')

  const [paraDID, setParaDid] = useState('')
  const [paraDescricao, setParaDescricao] = useState('')

  const getTicket = useCallback(async () => {
    try {
      setLoading(true)
      const { data } = await api.get(`/tickets/${id}`)

      data.inicio = data.inicio
        ? moment(data.inicio).format('DD/MM/YYYY HH:mm:ss')
        : ''
      data.termino = data.termino
        ? moment(data.termino).format('DD/MM/YYYY HH:mm:ss')
        : ''

      setTicket(data)
      setLoading(false)
    } catch (error) {
      toast.error('Erro ao carregar o ticket')
      setLoading(false)
    }
  }, [id])

  const getTabulations = useCallback(async () => {
    try {
      const { data } = await api.get(`/tabulacao/domain/${profile.id_dominio}`)
      setTabulations(data)
    } catch (error) {
      toast.error('Erro ao carregar as tabulações')
    }
  }, [profile.id_dominio])

  useEffect(() => {
    setStatus(ticket.aberto)
    setComentario(ticket.comentario)
    setTabulacaoLocal(ticket.fk_id_tabulacao)

    if (ticket.de && typeof ticket.de === 'string') {
      setDeDid(ticket.de)
    }
    if (ticket.de && typeof ticket.de === 'object') {
      setDeDid(ticket.de.did)
      setDeDescricao(ticket.de.descricao)
    }

    if (ticket.para && typeof ticket.para === 'string') {
      setParaDid(ticket.para)
    }
    if (ticket.para && typeof ticket.para === 'object') {
      setParaDid(ticket.para.did)
      setParaDescricao(ticket.para.descricao)
    }
  }, [ticket])

  useEffect(() => {
    getTicket()
    getTabulations()
  }, [getTicket, getTabulations])

  async function handleSubmit() {
    const data = {
      id,
      comentario,
      aberto: status,
      fk_id_tabulacao: tabulacao,
    }

    await api.put(`/tickets/${id}`, data)
    globalDispatch({
      type: 'SET_TICKET',
      payload: { ticket: [] },
    })

    toast.success('Ticket atualizado com sucesso')
    history.push('/chamados')
  }

  function handleTabulacao(id) {
    setTabulacaoLocal(id)
    setTicket({ ...ticket, fk_id_tabulacao: id, aberto: status, comentario })
  }

  function getTabulationName(tabulacoes, id) {
    // eslint-disable-next-line no-unused-vars
    for (const tabulacao of tabulacoes) {
      if (tabulacao.id === id) {
        return tabulacao.nome
      }

      if (tabulacao.tabulations.length > 0) {
        const tabulationName = getTabulationName(tabulacao.tabulations, id)
        if (tabulationName) {
          return tabulationName
        }
      }
    }
    return null
  }

  return (
    <FormContainer>
      {!loading ? (
        <Form onSubmit={handleSubmit}>
          <Message>
            <Form.Input name="id" value={id} label="ID:" readOnly />
            <Form.Input
              name="callid"
              value={ticket.call_id}
              label="CallID:"
              readOnly
            />

            {profile.tipo >= 2 && (
              // biome-ignore lint/a11y/useMediaCaption: <explanation>
              <audio
                controls
                src={`https://api.cloudcom.com.br/basix/recording/callid/${ticket.call_id}`}
                type="audio/mp3"
              />
            )}
            <Divider />
            <Form.Group widths="equal">
              <Form.Input name="inicio" value={ticket.inicio} label="Inicio:" />
              <Form.Input
                name="termino"
                value={ticket.termino}
                label="Termino:"
              />
              <Form.Select
                fluid
                name="status"
                label="Status:"
                options={options}
                placeholder="Selecione uma opção"
                value={status}
                onChange={(e, v) => setStatus(v.value)}
              />
            </Form.Group>
            <Divider />
            {typeof ticket.de === 'string' && (
              <Form.Input
                name="de.did"
                placeholder="De"
                label="De:"
                value={deDID}
                readOnly
              />
            )}

            {typeof ticket.de === 'object' && (
              <Form.Group widths="equal">
                <Form.Input
                  name="de.did"
                  placeholder="Numero"
                  label="Numero:"
                  value={deDID}
                  readOnly
                />
                <Form.Input
                  name="de.descricao"
                  placeholder="Nome"
                  label="Nome:"
                  value={deDescricao}
                  readOnly
                />
              </Form.Group>
            )}

            {typeof ticket.para === 'string' && (
              <Form.Input
                name="para"
                placeholder="Para"
                label="Para:"
                value={paraDID}
                readOnly
              />
            )}

            {typeof ticket.para === 'object' && (
              <Form.Group widths="equal">
                <Form.Input
                  name="para.did"
                  placeholder="Numero"
                  label="Numero:"
                  value={paraDID}
                  readOnly
                />
                <Form.Input
                  name="para.descricao"
                  placeholder="Nome"
                  label="Nome:"
                  value={paraDescricao}
                  readOnly
                />
              </Form.Group>
            )}
            <Divider />
            <div>
              {/* biome-ignore lint/a11y/noLabelWithoutControl: <explanation> */}
              <label>Tabulacao</label>
              <ContainerTabulacao>
                <Menu compact>
                  <Dropdown
                    text={
                      findItemNameById(ticket.fk_id_tabulacao, tabulations) ??
                      'Selecione uma tabulação'
                    }
                    pointing
                    className="link item titulo"
                  >
                    <DropdownMenu>
                      {tabulations.map((item) => {
                        return (
                          <CustomDropdownItem
                            key={item.id}
                            estrutura={item.tabulations}
                            item={item}
                            setTabulacao={handleTabulacao}
                          />
                        )
                      })}
                    </DropdownMenu>
                  </Dropdown>
                </Menu>
              </ContainerTabulacao>
            </div>

            {/* <Form.Input
              name="id"
              value={getTabulationName(tabulations, ticket.fk_id_tabulacao)}
              label="Tabulação:"
              readOnly
            /> */}
            <Divider />
            <label>Arquivos</label>
            {/* biome-ignore lint/a11y/noLabelWithoutControl: <explanation> */}
            <div>
              <input type="file" multiple hidden />
              <Button
                type="button"
                color="blue"
                onClick={() => {
                  const input = document.querySelector('input[type="file"]')
                  input.click()
                  input.onchange = async (e) => {
                    const files = e.target.files
                    const formData = new FormData()

                    let total = 0
                    for (let i = 0; i < files.length; i++) {
                      const findFile = ticket.files.find(
                        (file) =>
                          file.file_name === files[i].name &&
                          file.size === files[i].size,
                      )
                      if (findFile) {
                        toast.error(`Arquivo ${files[i].name} já existe`, {
                          autoClose: false,
                        })
                        continue
                      }

                      total++
                      formData.append('files', files[i])
                    }

                    if (total > 0) {
                      setIsUploading(true)
                      setUploadProgress(0)

                      try {
                        await api.post(
                          `tickets/${ticket.id}/upload`,
                          formData,
                          {
                            headers: {
                              'Content-Type': 'multipart/form-data',
                            },
                            onUploadProgress: (progressEvent) => {
                              const percentCompleted = Math.round(
                                (progressEvent.loaded * 100) /
                                  progressEvent.total,
                              )
                              setUploadProgress(percentCompleted)
                            },
                          },
                        )

                        getTicket()
                        // const response = await api.get('/tickets/open')
                        // globalDispatch({
                        //   type: 'SET_DATA',
                        //   payload: {
                        //     tickets: response.data,
                        //   },
                        // })
                      } catch (error) {
                        toast.error(
                          'Erro ao fazer upload do arquivo. O limit para arquivo 50MB.',
                          { autoClose: false },
                        )
                        console.error('Upload failed:', error)
                      } finally {
                        setIsUploading(false)
                        input.value = ''
                        setUploadProgress(0)
                      }
                    }
                    input.value = ''
                  }
                }}
                disabled={isUploading}
              >
                {isUploading ? 'Enviando...' : 'Anexar arquivos'}
              </Button>
            </div>
            <div
              style={{
                width: '150px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {isUploading && (
                <div
                  style={{
                    marginTop: '10px',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <div
                    style={{
                      width: '100%',
                      backgroundColor: '#e0e0e0',
                      borderRadius: '4px',
                      marginBottom: '5px',
                    }}
                  >
                    <div
                      style={{
                        width: `${uploadProgress}%`,
                        height: '10px',
                        backgroundColor: '#3b82f6',
                        borderRadius: '4px',
                        transition: 'width 0.3s ease',
                      }}
                    />
                  </div>
                  <span>{uploadProgress}%</span>
                </div>
              )}
            </div>
            {ticket.files && ticket.files.length > 0 && (
              <Table celled striped>
                <TableHeader>
                  <TableRow>
                    <TableHeaderCell colSpan="3">
                      Arquivos anexados
                    </TableHeaderCell>
                  </TableRow>
                </TableHeader>

                <TableBody>
                  {ticket.files.map((file) => {
                    return (
                      <TableRow key={file.id}>
                        <TableCell collapsing>
                          <Icon name="file outline" /> {file.file_name}
                        </TableCell>
                        <TableCell>
                          {(file.size / 1024).toFixed(2)} Kb
                        </TableCell>
                        <TableCell collapsing textAlign="right">
                          <Button
                            type="button"
                            color="blue"
                            onClick={async () => {
                              const { data } = await api.get(
                                `tickets/${ticket.id}/download/${file.id}`,
                              )

                              // O codigo abaixo esta com erro de cors, como contornar ?
                              window.open(data.url, '_blank')
                            }}
                          >
                            Download
                          </Button>
                          <Button
                            type="button"
                            color="red"
                            onClick={async () => {
                              await api.delete(
                                `tickets/${ticket.id}/delete/${file.id}`,
                              )

                              getTicket()
                            }}
                          >
                            Remover
                          </Button>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            )}
            <Divider />
            <Form.TextArea
              name="comentario"
              label="Comentarios da ligação"
              placeholder="detalhes da chamada..."
              value={comentario || ''}
              onChange={(e) => setComentario(e.target.value)}
            />
            <Button primary fluid type="submit">
              Alterar
            </Button>
          </Message>
        </Form>
      ) : (
        <Loader active />
      )}
    </FormContainer>
  )
}

Ticket.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
}
