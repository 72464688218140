/* eslint-disable jsx-a11y/label-has-for */
import React, { useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Form, Message, Button, FormField, Select } from 'semantic-ui-react'
import { toast } from 'react-toastify'

// import {
//   addContactRequest,
//   addContactBackDashboardRequest,
//   loadCustomParamRequest,
// } from '~/store/modules/contato/actions'
import { SemanticContainer } from './styles'
import api from '../../services/api'
import { useGlobalContext } from '../../contexts/GlobalContext'

export default function Contato({ location }) {
  const { globalState, globalDispatch } = useGlobalContext()
  console.log(globalState)

  const search = new URLSearchParams(location.search)
  const profile = useSelector((state) => state.user.profile)

  const [did, setDid] = useState(search.get('did') || '')
  const [descricao, setDescricao] = useState('')
  const [fraseologia, setFraseologia] = useState('')
  const [contactFields, setContactFields] = useState([])

  const getContactFields = useCallback(async () => {
    try {
      const { data } = await api.get(`/templatefields/${profile.id_dominio}`)

      console.log(data)

      setContactFields(data)
    } catch (error) {
      console.error('Error fetching contact fields:', error)
    }
  }, [profile.id_dominio])

  useEffect(() => {
    getContactFields()
  }, [getContactFields])

  function updateContactFields(event) {
    setContactFields(
      contactFields.map((item) => {
        if (item.nome_campo === event.target.name) {
          item.conteudo = event.target.value
        }
        return item
      }),
    )
  }

  async function handleAddField() {
    const { value: campo } = await Swal.fire({
      title: 'Adicionar novo campo',
      input: 'text',
      inputPlaceholder: 'Digite o nome do campo',
    })

    if (!campo) {
      return
    }

    if (contactFields.map((item) => item.nome_campo).includes(campo)) {
      await Swal.fire({
        type: 'error',
        title: 'Erro no nome do campo',
        text: 'Já existe um campos com este nome !',
      })
      return
    }

    if (campo && /^[a-z0-9]*$/.test(campo)) {
      setContactFields([
        ...contactFields,
        { nome_campo: campo, conteudo: '', tipo: 1 },
      ])
    } else {
      await Swal.fire({
        type: 'error',
        title: 'Erro no nome do campo',
        text: 'Aceito somente letras minusculas e numeros sem espaços !',
      })
    }
  }

  function handleDeletePhoneNumber(id, nome) {
    const MySwal = withReactContent(Swal)
    MySwal.fire({
      title: `Apagar campo: ${nome}`,
      text: 'Tem certeza que deseja deletar ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.value) {
        MySwal.fire({
          type: 'success',
          title: 'Campo deletado com sucesso',
        })

        setContactFields(
          contactFields.filter((item) => item.nome_campo !== nome),
        )

        // api.delete(`/contactField/${id}`).then((response) => {
        //   if (response.data.error) {
        //     MySwal.fire({
        //       type: 'error',
        //       title: 'Erro ao deletar campo',
        //       text: 'Para remover um campo de seleção, é necessário remover todas as opções do campo.',
        //     })
        //   } else {
        //     MySwal.fire({
        //       type: 'success',
        //       title: 'Campo deletado com sucesso',
        //     })

        //     setContactFields(
        //       contactFields.filter((item) => item.nome_campo !== nome),
        //     )
        //   }
        // })
      }
      return false
    })
  }

  async function handleSubmit() {
    // const obj = {
    //   did,
    //   descricao,
    //   fraseologia,
    //   ContactFields: contactFields,
    // }
    // const { data } = await api.post('/contact', obj)
    // toast.success(`Contato adicionado com id: ${data.id}`)
    // globalDispatch({
    //   type: 'ADD_CONTACT',
    //   payload: { tickets: [] },
    // })
  }

  const handleAddContactBackDashboard = async () => {
    const obj = {
      did,
      descricao,
      fraseologia,
      ContactFields: contactFields,
      idTicket: search.get('ticket'),
    }

    const { data } = await api.post('/contacts', obj)
    toast.success(`Contato adicionado com id: ${data.id}`)
    globalDispatch({
      type: 'SET_DATA',
      payload: {
        tickets: [],
      },
    })
  }

  return (
    <SemanticContainer>
      <Form onSubmit={handleSubmit}>
        <Message>
          <Form.Input
            name="did"
            fluid
            label="Numero"
            placeholder="Numero"
            value={did || ''}
            onChange={(e) => setDid(e.target.value)}
          />
          <Form.Input
            name="descricao"
            fluid
            label="Nome"
            placeholder="Nome do usuário"
            value={descricao || ''}
            onChange={(e) => setDescricao(e.target.value)}
          />

          <Form.Group className="fields_personalizados">
            {contactFields?.map((field) => {
              if (field.tipo === 1) {
                return (
                  <Form.Input
                    key={field.nome_campo}
                    name={field.nome_campo}
                    fluid
                    label={field.nome_campo}
                    placeholder={field.nome_campo}
                    value={field.conteudo}
                    onChange={(e) => updateContactFields(e)}
                    action={{
                      type: 'button',
                      color: 'red',
                      icon: 'delete',
                      onClick: () => {
                        handleDeletePhoneNumber(field.id, field.nome_campo)
                      },
                    }}
                  />
                )
              }
              if (field.tipo === 2) {
                return (
                  <div className="field field_select">
                    <FormField
                      className="field_select"
                      control={Select}
                      fluid
                      label={field.nome_campo}
                      options={[
                        ...field.template_fields_values.map((item) => {
                          return {
                            key: item.id,
                            text: item.valor,
                            value: item.id,
                          }
                        }),
                      ]}
                      value={field.conteudo}
                      onChange={(e, { value }) => {
                        field.conteudo = value
                        setContactFields([...contactFields])
                      }}
                      placeholder={'campo de seleção'}
                    />
                  </div>
                )
              }
            })}
          </Form.Group>

          <Button fluid positive type="button" onClick={() => handleAddField()}>
            Adicionar campo
          </Button>
          <Form.TextArea
            name="fraseologia"
            label="Fraseologia"
            placeholder="Fraseologia de atendimento"
            value={fraseologia || ''}
            onChange={(e) => setFraseologia(e.target.value)}
          />
          {/* <Button fluid primary type="submit">
            Adicionar
          </Button> */}

          {/* <br /> */}

          <Button
            fluid
            primary
            type="button"
            onClick={() => {
              handleAddContactBackDashboard()
            }}
          >
            {/* Adicionar e voltar para o Ticket {search.get('ticket')} */}
            Adicionar
          </Button>
        </Message>
      </Form>
    </SemanticContainer>
  )
}

Contato.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
}
