import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import {
  Form,
  Message,
  Loader,
  Button,
  FormField,
  Select,
} from 'semantic-ui-react'
import { useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import api from '~/services/api'
import { ContainerSemantic } from './styles'
import { toast } from 'react-toastify'
import { useGlobalContext } from '../../contexts/GlobalContext'

export default function Contato({ match }) {
  const { globalDispatch } = useGlobalContext()
  const profile = useSelector((state) => state.user.profile)
  const { id } = match.params

  const [contact, setContact] = useState({})
  const [loading, setLoading] = useState(false)
  const [descricao, setDescricao] = useState('')
  const [contactNumbers, setContactNumbers] = useState([])
  const [contactFields, setContactFields] = useState([])
  const [templateFields, setTemplateFields] = useState([])
  const [fraseologia, setFraseologia] = useState('')

  const getTemplateFields = useCallback(async () => {
    try {
      const response = await api.get(`/templatefields/${profile.id_dominio}`)
      setTemplateFields(response.data)
    } catch (error) {
      toast.error(error.response.data.error)
    }
  }, [profile])

  const getContact = useCallback(async () => {
    setLoading(true)
    try {
      const response = await api.get(`/contacts/${id}`)
      setContact(response.data)

      setDescricao(response.data.descricao)
      setContactNumbers(response.data.ContactNumbers)
      setContactFields(response.data.ContactFields)
      setFraseologia(response.data.fraseologia)
    } catch (error) {
      toast.error(error.response.data.error)
    }
    setLoading(false)
  }, [id])

  useEffect(() => {
    getContact()
  }, [getContact])

  useEffect(() => {
    getTemplateFields()
  }, [getTemplateFields])

  function setValue(value, target) {
    const novoArray = templateFields.map((item) => {
      if (item.nome_campo === target) {
        item.conteudo = value
      }
      return item
    })
    setTemplateFields(novoArray)

    const novoArray2 = contactFields.map((item) => {
      if (item.nome_campo === target) {
        item.conteudo = value
      }
      return item
    })
    setContactFields(novoArray2)
  }

  async function handleAddPhoneNumber() {
    const { value: numero } = await Swal.fire({
      title: 'Adicionar novo número',
      input: 'text',
      inputPlaceholder: 'Digite o número',
    })

    const retorno = contactNumbers.filter((item) => item.numero === numero)
    if (retorno.length > 0) {
      const MySwal = withReactContent(Swal)
      return MySwal.fire({
        icon: 'error',
        title: 'Erro ao adicionar',
        text: 'Este número já existe !',
      })
    }

    if (numero) {
      try {
        await api.post(`/contacts/${id}/phones`, { number: numero })
      } catch (error) {
        toast.error(error.response.data.error)
      }

      getContact()
    }
    return false
  }

  async function handleAddField() {
    const { value: campo } = await Swal.fire({
      title: 'Adicionar novo campo',
      input: 'text',
      inputPlaceholder: 'Digite o nome do campo',
    })

    const retorno = contactFields.filter((item) => item.nome_campo === campo)
    if (retorno.length > 0) {
      const MySwal = withReactContent(Swal)
      return MySwal.fire({
        icon: 'error',
        title: 'Erro ao adicionar',
        text: 'Este campo já existe !',
      })
    }

    if (campo) {
      setContactFields([...contactFields, { nome_campo: campo, conteudo: '' }])
      // dispatch(addCustomParamRequest({ name: campo }))
      // api.post('templatefields', {
      //   name,
      //   id_dominio,
      //   tipo,
      // })
    }
    return false
  }

  async function handleDeleteNumber(data) {
    const MySwal = withReactContent(Swal)
    MySwal.fire({
      title: `Remover numero: ${data.number}`,
      text: 'Tem certeza que deseja deletar ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
    }).then(async (result) => {
      if (result.value) {
        await api.delete(`/contacts/${id}/phones/${data.id}`)
        getContact()
      }
      return false
    })
  }

  function handleDeleteField(data) {
    const MySwal = withReactContent(Swal)
    MySwal.fire({
      title: `Apagar campo: ${data.name}`,
      text: 'Tem certeza que deseja deletar ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.value) {
        api.delete(`/contactField/${data.id}`).then((response) => {
          if (response.data.error) {
            toast.error(response.data.error)
          } else {
            toast.success('Campo deletado')
            getContact()
          }
        })
      }
      return false
    })
  }

  function handleSubmit() {
    // alert('implementar handleSubmit')
    api
      .put(`/contacts/${id}`, {
        ...contact,
        descricao,
        fraseologia,
        ContactFields: contactFields,
      })
      .then((response) => {
        if (response.data.error) {
          toast.error(response.data.error)
        } else {
          globalDispatch({
            type: 'SET_DATA',
            payload: {
              tickets: [],
            },
          })
          toast.success('Contato atualizado')
        }
      })
      .catch((error) => {
        toast.error(error.response.data.error)
      })
    // dispatch(updateContactRequest({ ...contact, did, descricao, fraseologia }))
  }

  return (
    <ContainerSemantic>
      {!loading ? (
        <Form onSubmit={handleSubmit}>
          <Message>
            <Form.Group widths="equal">
              <Form.Input
                name="descricao"
                fluid
                label="Nome"
                placeholder="Nome do usuário"
                value={descricao}
                onChange={(e) => setDescricao(e.target.value)}
              />
            </Form.Group>
          </Message>
          {contactNumbers && contactNumbers.length > 0 && (
            <Message>
              <Form.Group className="fields_personalizados">
                {contactNumbers?.map((field) => {
                  return (
                    <Form.Input
                      key={field.numero}
                      name={field.numero}
                      fluid
                      label="numero"
                      placeholder={field.numero}
                      value={field.numero}
                      // onChange={(e) => {
                      //   setValuePhone(e.target.value, field.numero)
                      // }}
                      action={{
                        type: 'button',
                        color: 'red',
                        icon: 'delete',
                        onClick: () => {
                          handleDeleteNumber({
                            id: field.id,
                            number: field.numero,
                          })
                        },
                      }}
                    />
                  )
                })}
              </Form.Group>
            </Message>
          )}
          <Button
            fluid
            positive
            type="button"
            onClick={() => handleAddPhoneNumber()}
            style={{ marginBottom: '10px' }}
          >
            Adicionar telefone
          </Button>
          {contactFields && contactFields.length > 0 && (
            <Message>
              <Form.Group className="fields_personalizados">
                {contactFields?.map((field) => {
                  const findFieldConfig = templateFields.find(
                    (item) => item.nome_campo === field.nome_campo,
                  )

                  if (!findFieldConfig) {
                    return (
                      <Form.Input
                        key={field.id}
                        name={field.nome_campo}
                        fluid
                        label={field.nome_campo}
                        placeholder={field.nome_campo}
                        value={field.conteudo}
                        onChange={(e, { value }) => {
                          setValue(value, field.nome_campo)
                        }}
                        action={{
                          type: 'button',
                          color: 'red',
                          icon: 'delete',
                          onClick: () => {
                            handleDeleteField({
                              id: field.id,
                              name: field.nome_campo,
                              contact_id: contact.id,
                            })
                          },
                        }}
                      />
                    )
                  }

                  if (findFieldConfig.tipo === 1) {
                    return (
                      <Form.Input
                        key={field.id}
                        name={field.nome_campo}
                        fluid
                        label={field.nome_campo}
                        placeholder={field.nome_campo}
                        value={field.conteudo}
                        onChange={(e, { value }) => {
                          setValue(value, field.nome_campo)
                        }}
                        action={{
                          type: 'button',
                          color: 'red',
                          icon: 'delete',
                          onClick: () => {
                            handleDeleteField({
                              id: field.id,
                              name: field.nome_campo,
                              contact_id: contact.id,
                            })
                          },
                        }}
                      />
                    )
                  }

                  if (findFieldConfig.tipo === 2) {
                    return (
                      <div className="field field_select" key={field.id}>
                        <FormField
                          className="field_select"
                          control={Select}
                          fluid
                          label={field.nome_campo}
                          options={[
                            ...findFieldConfig.template_fields_values.map(
                              (item) => {
                                return {
                                  key: item.id,
                                  text: item.valor,
                                  value: item.id,
                                }
                              },
                            ),
                          ]}
                          value={Number.parseInt(field.conteudo, 10)}
                          onChange={(e, { value }) => {
                            setValue(value, field.nome_campo)
                          }}
                          placeholder={'campo de seleção'}
                        />
                      </div>
                    )
                  }
                })}
              </Form.Group>
            </Message>
          )}
          <Button fluid positive type="button" onClick={() => handleAddField()}>
            Adicionar campo
          </Button>
          <Message>
            <Form.TextArea
              name="fraseologia"
              label="Fraseologia"
              placeholder="Fraseologia de atendimento"
              value={fraseologia}
              onChange={(e) => setFraseologia(e.target.value)}
            />
          </Message>
          <Button fluid primary type="submit">
            Atualizar
          </Button>
        </Form>
      ) : (
        <Loader active />
      )}
    </ContainerSemantic>
  )
}

Contato.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
}
